export default {
  namespaced: true,
  state: {
    isFilter: false,
    search: "",
    tagsFilter: [],

    categoriesOpened: [],
    imagesToHide: [],
  },
  getters: {
    isFilter(state) {
      return state.isFilter;
    },
    search(state) {
      return state.search;
    },
    tagsFilter(state) {
      return state.tagsFilter;
    },

    categoriesOpened(state) {
      return state.categoriesOpened;
    },
    imagesToHide(state) {
      return state.imagesToHide;
    },
  },
  mutations: {
    setIsFilter(state, data) {
      state.isFilter = data;
    },
    setSearch(state, data) {
      state.search = data;
    },
    setTagsFilter(state, data) {
      state.tagsFilter = data;
    },

    setCategoriesOpened(state, data) {
      state.categoriesOpened = data;
    },
    setImagesToHide(state, data) {
      state.imagesToHide = data;
    },
  },
  actions: {
    setIsFilter({ commit }, data) {
      commit("setIsFilter", data);
    },
    setSearch({ commit }, data) {
      commit("setSearch", data);
    },
    clearTagsFilter({ commit }) {
      commit("setTagsFilter", []);
    },
    updateTagsFilter({ commit, getters }, data) {
      if (getters.tagsFilter.find((i) => i.name === data.name)) {
        commit(
          "setTagsFilter",
          getters.tagsFilter.filter((i) => i.name !== data.name),
        );
      } else {
        commit("setTagsFilter", [...getters.tagsFilter, data]);
      }
    },

    updateCategoriesOpened({ getters, commit }, data) {
      const categoryId = getters.categoriesOpened.find((i) => i === data);
      if (categoryId) {
        commit(
          "setCategoriesOpened",
          getters.categoriesOpened.filter((i) => i !== data),
        );
      } else {
        commit("setCategoriesOpened", [...getters.categoriesOpened, data]);
      }
    },
    setImagesToHide({ commit }, data) {
      commit("setImagesToHide", data);
    },
    addImageToHide({ dispatch, getters }, data) {
      dispatch("setImagesToHide", [...getters.imagesToHide, data]);
    },
  },
};
